import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import Dropzone from "react-dropzone"
import { AvForm, AvField } from "availity-reactstrap-validation"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
} from "reactstrap"

import {
  addNewProject,
  getProjects,
} from "../../store/projects/actions"

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class ProjectsCreate extends Component {
  constructor() {
    super()
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      selectedFiles: [],
      projects: [],
    }
    this.startDateChange.bind(this)
    this.endDateChange.bind(this)
    this.handleAcceptedFiles.bind(this)
    this.handleValidProjectSubmit = this.handleValidProjectSubmit.bind(this)
  }

  componentDidMount() {
    const { projects, onGetProjects } = this.props;
    onGetProjects()
    this.setState({ projects })
  }

  handleValidProjectSubmit = (project, values) => {

    const newProject = {
      id: Math.floor(Math.random() * (30 - 20)) + 20,
      img: values["img"],
      name: values["name"],
      description: values["description"],
      status: values["status"],
      color: values["color"],
      dueDate: values["dueDate"],
      team: values["team"]
    }

  }

  startDateChange = date => {
    this.setState({
      startDate: date,
    })
  }

  endDateChange = date => {
    this.setState({
      endDate: date,
    })
  }

  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  render() {
    const { projects } = this.props
    
    return (
      <React.Fragment>
        <div className="page-content">
        
          <MetaTags>
            <title>Create New | Skote - React Admin & Dashboard Template</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Projects" breadcrumbItem="Create New" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Create New Project</CardTitle>
                    <AvForm onValidSubmit={
                      this.handleValidProjectSubmit
                    }>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="projectname"
                          className="col-form-label col-lg-2"
                        >
                          Project Name
                        </Label>
                        <Col lg="10">
                          <AvField
                            name="name"
                            type="text"
                            errorMessage="Invalid name"
                            validate={{
                              required: { value: true },
                            }}
                            className="form-control"
                            placeholder="Enter Project Name..."
                          />

                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="projectdesc"
                          className="col-form-label col-lg-2"
                        >
                          Project Description
                        </Label>
                        <Col lg="10">
                          <AvField
                            name="description"
                            type="textarea"
                            errorMessage="Invalid Description"
                            rows="3"
                            validate={{
                              required: { value: true },
                            }}
                            className="form-control"
                            placeholder="Enter Project Description..."
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">
                          Project Date
                        </Label>
                        <Col lg="10">
                          <Row>
                            <Col md="6" className="pe-md-0 md-pe-3">
                              <AvField
                                name="dueDate"
                                type="date"
                                errorMessage="Invalid To Date"
                                selected={this.state.startDate}
                                rows="3"
                                validate={{
                                  required: { value: true },
                                }}
                                className="form-control"
                                placeholder="Enter Project To Date..."
                              />

                            </Col>
                            <Col md="6" className="ps-md-0">
                              <AvField
                                name="fromDate"
                                type="date"
                                errorMessage="Invalid From Date"
                                rows="3"
                                validate={{
                                  required: { value: true },
                                }}
                                className="form-control"
                                placeholder="Enter From Date..."
                              />

                            </Col>
                          </Row>
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <label
                          htmlFor="projectbudget"
                          className="col-form-label col-lg-2"
                        >
                          Budget
                        </label>
                        <Col lg="10">
                          <AvField
                            name="projectbudget"
                            type="text"
                            errorMessage="Invalid Project Budget"
                            rows="3"
                            validate={{
                              required: { value: true },
                            }}
                            className="form-control"
                            placeholder="Enter Project Budget..."
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">
                          Attached Files
                      </Label>
                        <Col lg="10">

                          <Dropzone
                            onDrop={acceptedFiles =>
                              this.handleAcceptedFiles(acceptedFiles)
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {this.state.selectedFiles.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>
                        </Col>
                      </FormGroup>
                      <Row className="justify-content-end">
                        <Col lg="10">
                          <Button type="submit" color="primary">
                            Create Project
                        </Button>
                        </Col>
                      </Row>

                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectsCreate.propTypes = {
  projects: PropTypes.array,
  onGetProjects: PropTypes.func,
  onAddNewProject: PropTypes.func,
}

const mapStateToProps = ({ projects }) => ({
  projects: projects.projects,
})

const mapDispatchToProps = dispatch => ({
  onGetProjects: () => dispatch(getProjects()),
  onAddNewProject: project => dispatch(addNewProject(project)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectsCreate))